import React, { useState, useEffect, useRef } from 'react';

interface LazyImageProps {
  src: string;
  alt: string;
  className?: string;
  containerClassName?: string;
  onLoad?: () => void;
  onError?: () => void;
}

const LazyImage: React.FC<LazyImageProps> = ({ 
  src, 
  alt, 
  className = '', 
  containerClassName = '',
  onLoad: propOnLoad,
  onError: propOnError
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setShouldLoad(true);
            observer.disconnect();
          }
        });
      },
      {
        rootMargin: '50px', // Start loading images when they're 50px from viewport
        threshold: 0.1
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleLoad = () => {
    // console.log(`✅ Image loaded successfully: ${alt}`);
    setIsLoading(false);
    propOnLoad?.();
  };

  const handleError = () => {
    console.log(`❌ Image failed to load: ${alt}`);
    setError(true);
    setIsLoading(false);
    propOnError?.();
  };

  return (
    <div 
      ref={imageRef}
      className={`relative overflow-hidden ${containerClassName}`}
      data-loading={isLoading}
      data-error={error}
    >
      {/* Loading skeleton */}
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse">
          <div className="h-full w-full flex items-center justify-center">
            <span className="text-gray-400 text-sm">Loading...</span>
          </div>
        </div>
      )}
      
      {/* Only render image when it should load */}
      {shouldLoad && (
        <img
          src={src}
          alt={alt}
          className={`
            ${className}
            ${isLoading ? 'opacity-0' : 'opacity-100'}
            transition-opacity duration-300
          `}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}

      {/* Error state */}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <span className="text-gray-400 text-sm">Failed to load image</span>
        </div>
      )}
    </div>
  );
};

export default LazyImage;