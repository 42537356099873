import React, { useState } from 'react';
import { Mail, X, CheckCircle, AlertTriangle } from 'lucide-react';
import { api } from '../services/api';
import { Analytics } from '../services/analytics';

interface EmailConsentModalProps {
  onClose: () => void;
  onConsent: (consented: boolean) => void;
}

const EmailConsentModal: React.FC<EmailConsentModalProps> = ({ onClose, onConsent }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleConsent = async (consented: boolean) => {
    setIsSubmitting(true);
    setError(null);
    
    try {
      const response = await api.post('/user-profiles/update_email_consent/', {
        consent: consented
      });
      
      // Log the event
      Analytics.logEvent('Email Consent', consented ? 'Accepted' : 'Declined', {
        source: 'login_modal'
      });
      
      onConsent(consented);
      onClose();
    } catch (err) {
      setError('Sorry, there was a problem saving your preference. Please try again.');
      Analytics.logError('Email Consent', 'Failed to update consent', 'medium');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-md w-full shadow-xl overflow-hidden">
        <div className="px-6 pt-5 pb-3">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center text-blue-600">
              <Mail className="w-5 h-5 mr-2" />
              <h3 className="text-lg font-semibold">Stay Updated</h3>
            </div>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors"
              disabled={isSubmitting}
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <div className="mb-5">
            <p className="text-gray-700 mb-3">
              Would you like to receive occasional updates about new features and improvements to Eatsy?
            </p>
            <p className="text-sm text-gray-500">
              We'll only email about product updates and never share your email address with third parties. You can unsubscribe at any time from your profile.
            </p>
            
            {error && (
              <div className="mt-3 p-2 bg-red-50 text-red-600 rounded-md flex items-center text-sm">
                <AlertTriangle className="w-4 h-4 mr-2 flex-shrink-0" />
                {error}
              </div>
            )}
          </div>
        </div>
        
        <div className="px-6 py-4 bg-gray-50 flex justify-end space-x-3">
          <button
            onClick={() => handleConsent(false)}
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Not Now
          </button>
          <button
            onClick={() => handleConsent(true)}
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 flex items-center"
          >
            {isSubmitting ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <CheckCircle className="w-4 h-4 mr-2" />
                Yes, I'd Like Updates
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailConsentModal;