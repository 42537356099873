import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, useLocation, Navigate } from 'react-router-dom';
import { UserProfile, fetchUserProfile } from './services/api';
import HomePage from './components/HomePage';
import VenuePage from './components/VenuePage';
import UserDashboardPage from './components/UserDashboardPage';
import Login from './components/Login';
import AnimatedBackground from './components/AnimatedBackground';
import Footer from './components/Footer';
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import TermsOfService from './components/Footer/TermsOfService';
import { User, ChevronDown, LogOut, Trash2 } from 'lucide-react';
import Logo from './components/Logo';
import DeleteAccountModal from './components/DeleteAccountModal';
import { deleteUserAccount } from './services/api';
import UserMenu from './components/UserMenu';
import Analytics from './services/analytics';
import AdminDashboard from './components/AdminDashboard';
import OpenTableAvailability from './components/OpenTableAvailability';
import { SubscriptionProvider, ProBadge, ProtectedFeature } from './components/SubscriptionFeatures';
import { Home, Clock} from 'lucide-react';
import HeaderNav from './components/HeaderNav';
import { PaymentSuccess, PaymentCancel } from './components/PaymentComponents';
import EmailConsentModal from './components/EmailConsentModal';

const HeaderButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate('/')} className="text-2xl sm:text-3xl font-bold text-gray-900">
      <Logo />
    </button>
  );
};

const RouteTracker: React.FC = () => {
  const location = useLocation();
  const previousPath = useRef<string>('');

  useEffect(() => {
    // Only track if it's a new path
    if (location.pathname !== previousPath.current) {
      Analytics.pageview(location.pathname);
      previousPath.current = location.pathname;
    }
  }, [location]);

  return null;
};



const App: React.FC = () => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showConsent, setShowConsent] = useState(false);


    // Initialize Analytics
    useEffect(() => {
      Analytics.initialize();
    }, []);  

  const setUserProfileCallback = useCallback((newProfile: React.SetStateAction<UserProfile | null>) => {
    // console.log('Setting new user profile:', newProfile);
    setUserProfile(newProfile);
  }, []);

  useEffect(() => {
    const loadUserProfile = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const profile = await fetchUserProfile();
          setUserProfileCallback(profile);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          localStorage.removeItem('token');
          setUserProfileCallback(null);
        }
      }
    };
    loadUserProfile();
  }, [setUserProfileCallback]);

  useEffect(() => {
    // Show consent dialog if logged in and hasn't been shown yet
    if (userProfile && userProfile.email_consent_shown === false) {
      // Small delay to not show immediately after login
      const timer = setTimeout(() => setShowConsent(true), 1000);
      return () => clearTimeout(timer);
    }
  }, [userProfile]);
  

  const handleLogout = () => {
    Analytics.logAuth.signOut();  // Updated from Analytics.logLogout()
    localStorage.removeItem('token');
    localStorage.removeItem('googleFirstName');
    setUserProfileCallback(null);
  };

  

// The return statement in your App component
return (
  <GoogleOAuthProvider clientId="260065421156-6bor1a5hr1pkmhh42kqus63b6njbcjse.apps.googleusercontent.com">
    <SubscriptionProvider>
      <Router>
        <RouteTracker />
        <div className="relative min-h-screen bg-gray-100 flex flex-col">
          <AnimatedBackground />
          <header className="relative z-30 bg-white bg-opacity-95 shadow-sm border-b border-gray-200">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between items-center h-16 relative">
                {/* Left side with logo and nav */}
                <div className="flex items-center space-x-8">
                  <Logo />
                  <div className="hidden md:block">
                    <HeaderNav />
                  </div>
                </div>

                {/* Right side with user menu and mobile nav */}
                <div className="flex items-center space-x-1">
                  {userProfile ? (
                    <>
                      <UserMenu 
                        userProfile={userProfile}
                        onLogout={handleLogout}
                      />
                      <div className="md:hidden">
                        <HeaderNav onMenuClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
                      </div>
                    </>
                  ) : (
                    <>
                      <Login 
                        key="logged-out"
                        userProfile={null}
                        setUserProfile={setUserProfileCallback} 
                      />
                      <div className="md:hidden">
                        <HeaderNav onMenuClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Mobile Menu Overlay */}
            <div className="md:hidden">
              {/* Backdrop */}
              <div 
                className={`fixed inset-0 bg-black will-change-opacity
                            transition-opacity duration-300 ease-[cubic-bezier(0.4,0,0.2,1)]
                            ${isMobileMenuOpen 
                              ? 'opacity-20 z-40' 
                              : 'opacity-0 pointer-events-none'}`}
                onClick={() => setIsMobileMenuOpen(false)} 
              />
              {/* Menu Container - Use transform instead of max-height */}
              <div 
                className={`absolute top-16 left-0 right-0 bg-white will-change-transform
                            transition-all duration-300 ease-[cubic-bezier(0.4,0,0.2,1)]
                            ${isMobileMenuOpen 
                              ? 'translate-y-0 opacity-100 z-50 border-b border-gray-200' 
                              : 'translate-y-[-8px] opacity-0 -z-10 border-b border-transparent'}`}
                style={{
                  transform: `translateY(${isMobileMenuOpen ? '0' : '-8px'})`,
                  opacity: isMobileMenuOpen ? 1 : 0,
                  boxShadow: isMobileMenuOpen ? '0 2px 8px rgba(0,0,0,0.02)' : 'none',
                }}
              >
                {/* Menu Content */}
                <div className="py-2 px-4">
                  <div 
                    className={`transform transition-transform duration-300 ease-[cubic-bezier(0.4,0,0.2,1)]
                                ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-[-4px]'}`}
                  >
                    <Link 
                      to="/" 
                      className="flex items-center px-3 py-3 text-gray-600
                                transition-transform duration-200
                                hover:text-gray-900 hover:translate-x-0.5"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      <Home className="w-5 h-5 mr-2.5" />
                      <span className="font-medium">Tracker</span>
                    </Link>
                    <Link 
                      to="/availability" 
                      className="flex items-center px-3 py-3 text-gray-600
                                transition-transform duration-200
                                hover:text-gray-900 hover:translate-x-0.5"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      <Clock className="w-5 h-5 mr-2.5" />
                      <span className="font-medium">Availability</span>
                      <ProBadge className="ml-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-12 flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route 
                path="/venues/:city" 
                element={
                  <VenuePage 
                    userProfile={userProfile} 
                    setUserProfile={setUserProfileCallback} 
                  />
                } 
              />
              <Route
                path="/dashboard"
                element={<UserDashboardPage userProfile={userProfile} />}
              />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route 
                path="/availability" 
                element={
                  <ProtectedFeature userProfile={userProfile} setUserProfile={setUserProfileCallback}>
                    <OpenTableAvailability userProfile={userProfile} />
                  </ProtectedFeature>
                } 
              />
              <Route 
                path="/dashboard/admin" 
                element={
                  userProfile?.user?.is_superuser ? (
                    <AdminDashboard />
                  ) : (
                    <Navigate to="/" replace />
                  )
                } 
              />
              <Route path="/payment/success" element={<PaymentSuccess />} />
              <Route path="/payment/cancel" element={<PaymentCancel />} />
            </Routes>
          </main>

          <Footer />
          {showConsent && userProfile && (
            <EmailConsentModal 
              onClose={() => setShowConsent(false)}
              onConsent={(consented) => {  // Change to onConsent and handle the boolean parameter
                // Update local state to prevent showing the modal again
                if (userProfile) {
                  setUserProfileCallback({
                    ...userProfile,
                    email_consent: consented,  // Use the consented value
                    email_consent_shown: true
                  });
                }
              }}
            />
          )}

        </div>
      </Router>
    </SubscriptionProvider>
  </GoogleOAuthProvider>
);
};

export default App;